/**
* Created by tudou on 2020/4/12 18:38.
*/
<template>
  <div class="base-content">
    <form-frame
      :reset-visible="false"
      :submit-visible="false"
    >
      <div slot="content">
        <query-frame
          :searchVisible="false"
          :add-visible="false"
          :current="page.current"
          :total="page.total"
          @on-query="handleQuery"
          @on-reset="handleReset"
          @on-page-change="handlePageChange"
          @on-size-change="handleSizeChange"
        >
          <!-- 查询结果 开始 -->
          <template slot="table">
            <i-table :columns="columns" :dataSource="dataList">
            </i-table>
          </template>
          <!-- 查询结果 结束 -->
        </query-frame>
      </div>

    </form-frame>

  </div>
</template>

<script>
import { memberMatch } from '@/api/member';
import queryMixin from '@/mixin/queryMixin';
import FormFrame from '@/components/frame/FormFrame.vue';

export default {
  name: 'MemberMatch',
  mixins: [queryMixin],
  components: {
    FormFrame
  },
  props: {},
  data() {
    return {
      query: {
        phone: null,
      },
      // 默认展示表格
      columns: [
        {
          title: '比赛场次',
          dataIndex: 'matchSerial',
          key: 'matchSerial',
          ellipsis: true
        },
        {
          title: '最快单圈',
          dataIndex: 'bestTime',
          key: 'bestTime',
          ellipsis: true
        },
        {
          title: '完赛时间',
          dataIndex: 'matchTime',
          key: 'matchTime'
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 200,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row, index) => {
            return (
              <section>
                <a-button type="link" size="small" onClick={ () => this.handleScore(row) }>详情</a-button>
              </section>
            );
          }
        }
      ],
      dataList: []
    };
  },
  computed: {
    mobile() {
      return this.$route.params.mobile;
    },
  },
  watch: {},
  created() {
    console.log(this.mobile)
    this.handleQuery();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取会员列表
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;
      this.params.mobile = this.mobile;
      this.dataList = [];
      console.log(this.params)
      memberMatch(this.params).then((data) => {
        console.log(data)
        // const { totalCount, list } = data;
        this.page.total = data.length;
        // data.forEach((item) => {
        //   item.key = item.matchSerial
        // });
        this.dataList = data;
      });
    },
    // 查看成绩
    handleScore(row) {
      this.$router.push({
        name: 'memberScore',
        params: {
          matchSerial: row.matchSerial,
          userName: row.userName,
          openId: row.openId,
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>

</style>
